/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import {  EuiPage,
          EuiPageHeader,
          EuiPageHeaderSection,
          EuiPageBody,
          EuiPageContent,
          EuiPageContentBody,
          EuiImage  } from "@elastic/eui"

import '@elastic/eui/dist/eui_theme_amsterdam_dark.css'
import logo from "../images/hail-logo.png"

const Layout = ({ children }) => {
  return (
    <>
      <EuiPage paddingSize="none">
        <EuiPageBody>
          <EuiPageHeader
            restrictWidth
            paddingSize="l"
            rightSideItems={[<></>]}
          >
            <EuiPageHeaderSection>
              <EuiImage src={logo} alt="Hail CX" size="l" />
            </EuiPageHeaderSection>
          </EuiPageHeader>

          <EuiPageContent borderRadius="none" hasShadow={false} paddingSize="none">
            <EuiPageContentBody restrictWidth paddingSize="l">
              {children}
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
